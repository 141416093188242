import React, { useState, useEffect, useContext, useRef } from "react"
import Seo from "../components/seo"
import { graphql, Link, navigate } from "gatsby"
// components
import { useAuthContext } from "../hooks/useAuthContext"
import usePage from "../hooks/usePage"
import useUser from "../hooks/useUser"
import Widgets from "../helpers/widgets"
import { useDocument } from "../hooks/useDocument"
import { StateContext } from "../helpers/StateProvider"
import ExportPdfComponent from "../components/exportButton/ExportPdfComponent"
import ReactToPrint from "react-to-print"
import { Share } from "../components/LisitngDetails/ListingDetails.styles"
import emailjs from "emailjs-com"
import MustSignIn from "../components/MustSignIn"

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
} from "react-share"
import Faceboook from "../assets/icons/facebook-share.svg"
import Twitter from "../assets/icons/twitter-share.svg"
import Linkedin from "../assets/icons/linkedin-share.svg"
import Whatsapp from "../assets/icons/whatsapp-icon.svg"
import telegram from "../assets/icons/telegram_logo_circle_icon.svg"
import email from "../assets/icons/email-envelop.svg"
import useUserEmail from "../hooks/useUserEmail"

const IndexPage = props => {
  const { user, authIsReady } = useAuthContext()
  const [loading, setLoading] = useState(false)
  const [pageWidgets, setPageWidgets] = usePage("home")
  const [arabic, setArabic] = useState(false)

  const [country, setCountry] = useState("")
  const [city, setCity] = useState("")
  const [price, setPrice] = useState("")
  const [size, setSize] = useState("")
  const [location, setLocation] = useState("")
  const [neighbor, setNeighbor] = useState("")
  const [floor, setFloor] = useState("")
  const [bedroom, setBedroom] = useState("")
  const [living, setLiving] = useState("")
  const [salon, setSalon] = useState("")
  const [bathrooms, setBathrooms] = useState("")
  const [maid, setMaid] = useState("")
  const [dining, setDining] = useState("")
  const [laundry, setLaundry] = useState("")
  const [terrace, setTerrace] = useState("")
  const [balcony, setBlacony] = useState("")

  const [supermarket, setSupermarket] = useState("")
  const [transportation, setTransportation] = useState("")
  const [highways, setHighways] = useState("")
  const [restaurants, setRestaurants] = useState("")
  const [parks, setParks] = useState("")
  const [hospital, setHospital] = useState("")
  const [school, setSchool] = useState("")
  const [work, setWork] = useState("")

  const [other8, setOther8] = useState("")
  const [other7, setOther7] = useState("")
  const [other6, setOther6] = useState("")
  const [other5, setOther5] = useState("")
  const [other4, setOther4] = useState("")
  const [other3, setOther3] = useState("")
  const [other2, setOther2] = useState("")
  const [other1, setOther1] = useState("")

  const componentRef = useRef()
  const currentUrl = window.location.href
  useEffect(() => {
    setLoading(false)
  }, [user])
  const { language, setLanguage } = useContext(StateContext)
  const data = useUserEmail(user ? user.uid : 0)
  const handleSubmit = event => {
    event.preventDefault()
    console.log("test")
    emailjs
      .send(
        "opd_mailing_service",
        "template_i6lvg3m",
        {
          country: country,
          city: city,
          price: price,
          size:
            size +
            " " +
            floor +
            " " +
            bedroom +
            " " +
            living +
            " " +
            salon +
            " " +
            bathrooms +
            " " +
            maid +
            " " +
            dining +
            " " +
            laundry +
            " " +
            terrace +
            " " +
            balcony,
          location:
            supermarket +
            " " +
            transportation +
            " " +
            highways +
            " " +
            restaurants +
            " " +
            parks +
            " " +
            hospital +
            " " +
            school +
            " " +
            work,
          neighborhood: neighbor,
          other:
            other1 +
            " " +
            other2 +
            " " +
            other3 +
            " " +
            other4 +
            " " +
            other5 +
            " " +
            other6 +
            " " +
            other7 +
            " " +
            other8 +
            " ",
          details:
            "name: " +
            data.first_name +
            " " +
            data.last_name +
            " " +
            "email: " +
            data.email +
            " " +
            "phone: " +
            data.phone_number,
        },
        "g1dJssA6i85wnSF7J"
      )
      .then(() => {
        navigate("/")
      })
  }

  return (
    <>
      {authIsReady && (
        <>
          {!user && <MustSignIn />}
          {user && (
            <>
              <Seo title="needed" />
              <div
                ref={componentRef}
                className={
                  language == false
                    ? "container cards_about-us form_need"
                    : "text-right container cards_about-us"
                }
              >
                <form onSubmit={handleSubmit}>
                  <label className="margin_from">
                    {language == false ? " Name a Country" : "اسم البلد"}
                  </label>
                  <input
                    type="text"
                    name="country"
                    onChange={e => setCountry(e.target.value)}
                  ></input>
                  <label className="margin_from">
                    {language == false
                      ? "Name a City, Town, Village"
                      : " اسم مدينة ، بلدة ، قرية"}
                  </label>
                  <input
                    type="text"
                    name="city"
                    onChange={e => setCity(e.target.value)}
                  ></input>
                  <br />
                  <h4>
                    {" "}
                    {language == false
                      ? "Price Range in Dollars"
                      : "نطاق السعر بالدولار"}
                  </h4>
                  <br />
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="html"
                      name="price"
                      value="Between 100,000.00 and 150,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     {" "}
                    <label for="html">
                      {" "}
                      {language == false
                        ? "Between 100,000.00 and 150,000.00"
                        : "بين 100،000.00 و 150،000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="css"
                      name="price"
                      value="Between 151,000.00 and $200,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     {" "}
                    <label for="css">
                      {" "}
                      {language == false
                        ? "Between 151,000.00 and $200,000.00"
                        : "بين 151000.00 و 200.000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 201,000.00 and $250,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 201,000.00 and $250,000.00"
                        : "بين 201.000.00 و 250.000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 251,000.00 and $300,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 251,000.00 and $300,000.00"
                        : "بين 251،000.00 و 300،000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 301,000.00 and $350,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 301,000.00 and $350,000.00"
                        : "بين 301،000.00 و 350،000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 351,000.00 and $400,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 351,000.00 and $400,000.00"
                        : "بين 351،000.00 و 400،000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 401,000.00 and $450,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 401,000.00 and $450,000.00"
                        : "بين 401.000.00 و 450.000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 451,000.00 and $500,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 451,000.00 and $500,000.00"
                        : "بين 451،000.00 و 500،000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 501,000.00 and $550,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 501,000.00 and $550,000.00"
                        : "بين 501.000.00 و 550.000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 551,000.00 and $600,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 551,000.00 and $600,000.00"
                        : "بين 551،000.00 و 600،000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 601,000.00 and $650,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 601,000.00 and $650,000.00"
                        : "بين 601.000.00 و 650.000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 651,000.00 and $700,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 651,000.00 and $700,000.00"
                        : "بين 651.000.00 و 700.000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 701,000.00 and $750,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 701,000.00 and $750,000.00"
                        : "بين 701،000.00 و 750،000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 751,000.00 and $800,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 751,000.00 and $800,000.00"
                        : "بين 751،000.00 و 800،000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 801,000.00 and $850,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 801,000.00 and $850,000.00"
                        : "بين 801.000.00 و 850.000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 851,000.00 and $900,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 851,000.00 and $900,000.00"
                        : "بين 851،000.00 و 900،000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 901,000.00 and $950,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 901,000.00 and $950,000.00"
                        : "بين 901.000.00 و 950.000.00 دولار"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="price"
                      value="Between 951,000.00 and $1000,000.00"
                      onChange={e => setPrice(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {language == false
                        ? "Between 951,000.00 and $1000,000.00"
                        : "بين 951،000.00 و 1،000،000.00 دولار"}
                    </label>
                  </div>
                  {/* size */}
                  <br /> 
                  <h4> {language == false ? "Home Size" : "حجم المنزل"}</h4>
                  <br />
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="html"
                      name="size"
                      value="100 Sq. M"
                      onChange={e => setSize(e.target.value)}
                    />
                     {" "}
                    <label for="html">
                      {" "}
                      {language == false ? "100 Sq. M" : "100  مترا مربعا"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="css"
                      name="size"
                      value="130 Sq. M"
                      onChange={e => setSize(e.target.value)}
                    />
                     {" "}
                    <label for="css">
                      {" "}
                      {language == false ? "130 Sq. M" : "130  مترا مربعا"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="size"
                      value="150 Sq. M"
                      onChange={e => setSize(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "150 Sq. M" : "150  مترا مربعا"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="size"
                      value="175 Sq. M"
                      onChange={e => setSize(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "175 Sq. M" : "175 مترا مربعا"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="size"
                      value="200 Sq. M"
                      onChange={e => setSize(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? " 200 Sq. M" : "200  مترا مربعا"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="size"
                      value="250 Sq. M"
                      onChange={e => setSize(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "250 Sq. M" : "250  مترا مربعا"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="size"
                      value="300 sq. M"
                      onChange={e => setSize(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "300 sq. M" : "300 مترا مربعا"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="floor"
                      value="One Floor"
                      onChange={e => setFloor(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "One Floor" : "طابق واحد"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="floor"
                      value="Duplex"
                      onChange={e => setFloor(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Duplex" : "دوبلكس"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="floor"
                      value="Triplex"
                      onChange={e => setFloor(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Triplex" : "ثلاثي"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="bedroom"
                      value="One Bedroom"
                      onChange={e => setBedroom(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "One Bedroom" : "غرفة نوم واحدة "}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="bedroom"
                      value="Two Bedrooms"
                      onChange={e => setBedroom(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Two Bedrooms" : "غرفتا نوم"}{" "}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="bedroom"
                      value="Three Bedrooms"
                      onChange={e => setBedroom(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Three Bedrooms" : "غير محدد"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="living"
                      value="A Living Room"
                      onChange={e => setLiving(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "A Living Room" : "غرفة معيشة"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="salon"
                      value="A Salon"
                      onChange={e => setSalon(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "A Salon" : "صالون"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="bathrooms"
                      value="Two Bathrooms"
                      onChange={e => setBathrooms(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Two Bathrooms" : "حمامين"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="bathrooms"
                      value="Three Bathrooms"
                      onChange={e => setBathrooms(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Three Bathrooms" : "ثلاثة حمامات"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="maid"
                      value="Maid’s Room with Bathroom"
                      onChange={e => setMaid(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false
                        ? "Maid’s Room with Bathroom"
                        : "غرفة خادمة مع حمام"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="dining"
                      value="A dining Room"
                      onChange={e => setDining(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "A dining Room" : "غرفة الطعام"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="laundry"
                      value="Laundry Room"
                      onChange={e => setLaundry(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Laundry Room" : "غرفة الغسيل"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="terrace"
                      value="A Terrace"
                      onChange={e => setTerrace(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "A Terrace" : "الشرفة"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="balcony"
                      value="One Balcony"
                      onChange={e => setBlacony(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "One Balcony" : "شرفة واحدة"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="balcony"
                      value="Two Balconies"
                      onChange={e => setBlacony(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Two Balconies" : "شرفتان"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="balcony"
                      value="Three Balconies"
                      onChange={e => setBlacony(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Three Balconies" : "ثلاث شرفات"}
                    </label>
                  </div>
                  <br />
                  <h4> {language == false ? "Location" : "الموقع"}</h4>
                  <br />
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="html"
                      name="Work"
                      value="Close to Work"
                      onChange={e => setWork(e.target.value)}
                    />
                     {" "}
                    <label for="html">
                      {" "}
                      {language == false ? "Close to Work" : "قريب من العمل"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="css"
                      name="School"
                      value="Close to School"
                      onChange={e => setSchool(e.target.value)}
                    />
                     {" "}
                    <label for="css">
                      {" "}
                      {language == false
                        ? "Close to School"
                        : "على مقربة من المدرسة"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="Hospital"
                      value="Close to Hospital"
                      onChange={e => setHospital(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false
                        ? "Close to Hospital"
                        : "قريب من المستشفى"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="Parks"
                      value="Close to Parks/Recreation"
                      onChange={e => setParks(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false
                        ? "Close to Parks/Recreation"
                        : "قريب من سوبر ماركت"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="Restaurants"
                      value="Close to Restaurants"
                      onChange={e => setRestaurants(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false
                        ? "Close to Restaurants"
                        : "بالقرب من المتنزهات / الاستجمام"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="Highways"
                      value="Close to Highways"
                      onChange={e => setHighways(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false
                        ? "Close to Highways"
                        : "قريب من المطاعم"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="Transportation"
                      value="Public Transportation"
                      onChange={e => setTransportation(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false
                        ? "Public Transportation"
                        : "بالقرب من الطرق السريعة"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="Supermarket"
                      value="Close to Supermarket"
                      onChange={e => setSupermarket(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false
                        ? "Close to Supermarket"
                        : "قريب من سوبر ماركت"}
                    </label>
                  </div>
                  <br />
                  <h4> {language == false ? "Neighborhood" : "حيّ"}</h4>
                  <br />
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="html"
                      name="neighbor"
                      value="No Traffic"
                      onChange={e => setNeighbor(e.target.value)}
                    />
                     {" "}
                    <label for="html">
                      {" "}
                      {language == false ? "No Traffic" : "لا يوجد زحمة سير"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="css"
                      name="neighbor"
                      value="Public Transportation"
                      onChange={e => setNeighbor(e.target.value)}
                    />
                     {" "}
                    <label for="css">
                      {" "}
                      {language == false
                        ? "Public Transportation"
                        : "وسائل النقل العامة"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="neighbor"
                      value="Child-Friendly"
                      onChange={e => setNeighbor(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Child-Friendly" : "صديقة للأطفال"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="neighbor"
                      value="Very Quiet"
                      onChange={e => setNeighbor(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Very Quiet" : "هادئة جدا"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="neighbor"
                      value="Younger Neighbors"
                      onChange={e => setNeighbor(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Younger Neighbors" : "صغار الجيران"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="neighbor"
                      value="Older Neighbors"
                      onChange={e => setNeighbor(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false
                        ? "Older Neighbors"
                        : "الجيران الأكبر سنا "}
                    </label>
                  </div>
                  <br />
                  <h4>
                    {" "}
                    {language == false
                      ? "Other Required Specifications"
                      : "مواصفات أخرى مطلوبة"}
                  </h4>
                  <br />
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="html"
                      name="other8"
                      value="wooden floors"
                      onChange={e => setOther8(e.target.value)}
                    />
                     {" "}
                    <label for="html">
                      {" "}
                      {language == false ? "wooden floors" : "الأرضيات الخشبية"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="css"
                      name="other8h"
                      value="The maid room"
                      onChange={e => setOther8(e.target.value)}
                    />
                     {" "}
                    <label for="css">
                      {" "}
                      {language == false ? "The maid room" : "غرفة الخادمة"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="other7g"
                      value="Washroom"
                      onChange={e => setOther7(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Washroom" : "غرفة الغسيل"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="other6f"
                      value="Possibility to eat in the kitchen"
                      onChange={e => setOther6(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false
                        ? "Possibility to eat in the kitchen"
                        : "إمكانية تناول الطعام في المطبخ"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="other5e"
                      value="games room"
                      onChange={e => setOther5(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "games room" : "غرفة الالعاب"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="other4d"
                      value="Desk"
                      onChange={e => setOther4(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Desk" : "مكتب"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="other3c"
                      value="master bedroom"
                      onChange={e => setOther3(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false
                        ? "master bedroom"
                        : "غرفة النوم الرئيسية"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="other2b"
                      value="main bathroom"
                      onChange={e => setOther2(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "main bathroom" : "الحمام الرئيسي"}
                    </label>
                  </div>
                  <br /> 
                  <div
                    className={language == false ? "d-flex" : "d-flex arneeded"}
                  >
                    <input
                      type="radio"
                      id="javascript"
                      name="other1a"
                      value="Walk in Closet"
                      onChange={e => setOther1(e.target.value)}
                    />
                     
                    <label for="javascript">
                      {" "}
                      {language == false ? "Walk in Closet" : "خزانة كبيرة"}
                    </label>
                  </div>
                  <br /> 
                  <input
                    className="submit_button need_button"
                    type="submit"
                    value={arabic == false ? "Submit" : "أرسل"}
                  />
                </form>
                <Share>
                  <div className="text">
                    {arabic == false ? "Share on" : "مشاركه فى"}
                  </div>
                  <div className="socials">
                    <FacebookShareButton
                      url={currentUrl}
                      title="Facebook"
                      hashtag="#property"
                    >
                      <img src={Faceboook} alt="facebook icon" />
                    </FacebookShareButton>
                    <TwitterShareButton url={currentUrl} title="Twitter">
                      <img src={Twitter} alt="twitter icon" />
                    </TwitterShareButton>
                    <LinkedinShareButton url={currentUrl} title="Linkedin">
                      <img src={Linkedin} alt="linkedin icon" />
                    </LinkedinShareButton>
                    <WhatsappShareButton url={currentUrl} title="Whatsapp">
                      <img src={Whatsapp} alt="whatsapp icon" />
                    </WhatsappShareButton>
                    <TelegramShareButton url={currentUrl} title="telegram">
                      <img
                        src={telegram}
                        alt="whatsapp icon"
                        style={{ width: "64px" }}
                      />
                    </TelegramShareButton>
                    <EmailShareButton
                      url={currentUrl}
                      subject="share page"
                      body={"this is link shared"}
                    >
                      <img
                        src={email}
                        alt="whatsapp icon"
                        style={{ width: "50px" }}
                      />
                    </EmailShareButton>
                  </div>
                </Share>
              </div>

              <ReactToPrint
                content={() => componentRef.current}
                trigger={() => (
                  <button className="btn btn-primary">Print to PDF!</button>
                )}
              />
            </>
          )}
        </>
      )}
    </>
  )
}
export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allNews(limit: 3, sort: { fields: created_at }) {
      edges {
        node {
          description
          featured_image
          id
          overview
          title
          topic_title
          topic
          created_at
          localImage {
            childImageSharp {
              fixed(width: 300, height: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allEvents(limit: 2, sort: { fields: date }) {
      edges {
        node {
          date
          city
          address
          name
          id
          country
          thumbnail {
            url
          }
        }
      }
    }
    allPage(filter: { name: { eq: "home" } }) {
      edges {
        node {
          name
          title
          id
          description
          widgets {
            name
            type
          }
        }
      }
    }
    allHero {
      edges {
        node {
          image {
            url
          }
          localImage {
            childImageSharp {
              fixed(width: 2000, height: 800) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          id
          title
          title_ar
          paragraph
          paragraph_ar
          tagline
          tagline_ar
          link {
            text_ar
            text
            url
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id

          label
          label_ar
          link
          type
        }
      }
    }
    widget: allWidget {
      edges {
        node {
          description
          id
          image

          link_text
          link
          name
          title
          type
        }
      }
    }
    widget1: widget(name: { eq: "how-we-works" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
    }
    widget2: widget(name: { eq: "property-type" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description_ar
        title_ar
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 500) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget3: widget(name: { eq: "explore-properties" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget4: widget(name: { eq: "our-latest-news" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget5: widget(name: { eq: "it-is-time-to-expect-more" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget6: widget(name: { eq: "our-latest-events" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default IndexPage
